import React from 'react';
import "./About.css";
import images from '../../Constants/images';

const About = () => {
  return (
    <section className='about section-p bg-dark' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                <div className = "content-left">
                    <img src = {images.about_main_img} alt = "" />
                </div>
                <div className='content-right'>
                    <div className='section-t'>
                        <h3>About Us</h3>
                    </div>
                    <p className = "text">At Noon, we specialize in delivering tailored software solutions that drive innovation and business growth. Our team of experienced developers and designers work closely with clients to understand their unique challenges and goals, ensuring each project is crafted to meet specific needs. Whether you're an individual, a startup, or a growing business, we provide scalable, reliable, and user-friendly solutions that streamline operations and enhance customer experiences. With a focus on quality, creativity, and collaboration, we’re committed to helping businesses succeed in an ever-evolving digital landscape.</p>
                    {/* <p className='text'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur nobis laudantium nesciunt maxime tenetur illo.</p> */}
                </div>
            </div>
        </div>
    </section>
  )
}

export default About