import images from "./images";
import {FaEdit, FaRocket, FaShoppingCart, FaCar} from "react-icons/fa";
import {IoIosSchool} from "react-icons/io";
// import {BiDollarCircle} from "react-icons/bi";
import {ImMagicWand} from "react-icons/im";
import {AiOutlineReload} from "react-icons/ai";
// import images from "./images";
import {FaPaperPlane, FaFileAlt, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt} from "react-icons/fa";
// import {BiDollarCircle} from "react-icons/bi";
// import {ImMagicWand} from "react-icons/im";
// import {AiOutlineReload} from "react-icons/ai";


const gradient = "url(#blue-gradient)" ;

const services = [
    {
        id: 1,
        icon: <FaCar style = {{ fill: gradient }} />,
        title: "Car Contracts",
        text: "Simplifies car contract creation between buyers and sellers online."
    },
    {
        id: 2,
        icon: <IoIosSchool style = {{ fill: gradient }} />,
        title: "School System",
        text: "Organizes homework, grades, teachers, and students for efficient management."
    },
    {
        id: 3,
        icon: <FaRocket style = {{ fill: gradient }} />,
        title: "Branding",
        text: "Crafting unique, impactful brands tailored to your company’s identity."
    },
    {
        id: 4, 
        icon: <FaEdit style = {{ fill: gradient }} />,
        title: "Content Creation",
        text: "Managing and creating engaging social media content for businesses."
    },
    {
        id: 5,
        icon: <ImMagicWand style = {{ fill: gradient }} />,
        title: "Grpahic Design",
        text: "Designing logos, branding, identities, and custom visual content for clients."
    },
    {
        id: 6,
        icon: <FaShoppingCart style = {{ fill: gradient }} />,
        title: "Importing",
        text: "Facilitating smooth importation of goods from abroad for various industries."
    }
];

const about = [
    {
        id: 7,
        text: "At X, we specialize in delivering tailored software solutions that drive innovation and business growth. Our team of experienced developers and designers work closely with clients to understand their unique challenges and goals, ensuring each project is crafted to meet specific needs. Whether you're an individual, a startup, or a growing business, we provide scalable, reliable, and user-friendly solutions that streamline operations and enhance customer experiences. With a focus on quality, creativity, and collaboration, we’re committed to helping businesses succeed in an ever-evolving digital landscape."
    }
]

const qualities = [
    {
        id: 8,
        icon: <FaFileAlt style = {{ fill: gradient }} />,
        title: "Ideas & Plans",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod."
    },
    {
        id: 9,
        icon: <AiOutlineReload style = {{ fill: gradient }}  />,
        title: "Prompt Strategies",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod."
    }
];

const features = [
    {
        id: 10,
        title: "Car Contracts Windows App",
        text: "We specialize in building custom software solutions designed to meet your specific business needs. Our team works with you every step of the way—from initial consultation to final deployment—ensuring the software is efficient, scalable, and user-friendly. Whether it's a windows app, mobile solution, or enterprise platform, we deliver high-quality software that streamlines processes and drives growth."
    },
    {
        id: 11,
        title: "Trade Shows",
        text: "Our Car Contracts Windows App is designed to streamline the car sales process for dealerships and sellers. With this easy-to-use platform, car sales teams can quickly input customer and vehicle information, generating a fully formatted, legally compliant car sales contract on the spot. The app enables you to capture all necessary details, automatically populate contract fields, and produce a professional, printable contract for immediate signing. This system reduces paperwork, minimizes errors, and speeds up the sales process—helping you close deals faster and improve customer satisfaction."
    },
    {
        id: 12,
        title: "School System",
        text: "Our School System software helps educational institutions manage everything from student records and grades to homework assignments and teacher communication. This all-in-one solution improves administrative efficiency, enhances student-teacher interactions, and provides real-time insights into academic performance. With an intuitive interface, it’s easy to manage your school’s operations in one centralized platform"
    },
    {
        id: 13,
        title: "Branding",
        text: "We offer comprehensive branding services that create a strong, cohesive identity for your business. From logo design to messaging and visual aesthetics, our team works to build a brand that reflects your company's values and resonates with your audience. We ensure that every touchpoint of your brand—from your website to social media—projects a unified and impactful image."
    }, 
    {
        id: 14,
        title: "Content Creation",
        text: "Our content creation service is tailored to help your brand communicate effectively across digital platforms. Whether it’s blog posts, social media updates, videos, or graphics, we produce engaging content that captures attention, boosts engagement, and aligns with your marketing strategy. Let us help you tell your brand story in a way that connects with your target audience."
    },
    {
        id: 15,
        title: "Graphic Design",
        text: "From logos to promotional materials, our graphic design services bring your vision to life. We focus on creating visually compelling designs that enhance your brand’s identity and convey your message clearly. Whether you need a new brand identity, business cards, brochures, or custom digital designs, our talented designers create stunning visuals that stand out."
    }
];

const portfolio = [
    {
        id: 16,
        title: "Car Contract Windows App",
        text: "Streamlined car contracts, made quick and easy",
        image: images.portfolio_img_1,
    },
    {
        id: 17,
        title: "Graphic Design",
        text: "Creative designs that elevate your brand.",
        image: images.portfolio_img_2,
    },
    {
        id: 18,
        title: "Branding",
        text: "Unique brands that tell your story.",
        image: images.portfolio_img_3,
    }
];

const testimonials = [
    {
        id: 19,
        name: "Marie Jordan",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_1,
        rating: 3
    },
    {
        id: 20,
        name: "Ann Brown",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_2,
        rating: 5
    },
    {
        id: 21,
        name: "Andrew Bill",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_3,
        rating: 2
    },
    {
        id: 22,
        name: "Jason Stawer",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_4,
        rating: 4
    },
    {
        id: 23,
        name: "Lisa Green",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_5,
        rating: 2
    },
    {
        id: 24,
        name: "Anna Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_6,
        rating: 4
    }
]

const contact = [
    {
        id: 25,
        icon: <FaPhoneAlt style = {{ fill: gradient }} />,
        info: "+964 773 3810 890",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 26,
        icon: <FaEnvelopeOpen style = {{ fill: gradient }} />,
        info: "info@noon-iq.com",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 27,
        icon: <FaMapMarkerAlt style = {{ fill: gradient }} />,
        info: "Iraq, Baghdad",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    }
]






const sections = {services, about, qualities, features, portfolio, testimonials, contact};

export default sections;



