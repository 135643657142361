import Navbar from "../Navbar/Navbar";
import { FaPaperPlane } from "react-icons/fa";
import "./Header.css";

const Header = () => {
  return (
    <header className="header flex flex-center flex-column">
      <Navbar />

      <div className="container">
        <div className="header-content text-center flex flex-column">
          <img className="header-logo"
            src={require("../../assets/images/Logo.png")}
            alt="NoonSo Logo"
          />
          <h1 className="text-uppercase header-title">noon solution</h1>
          <p className="text-lead">
            Noon offers custom software development and design services. Our
            developers create customized software for individuals, start-ups and
            small and medium-sized businesses.
          </p>
          {/* <a href="/" className="btn header-btn btn-blue">
                <FaPaperPlane/>
              <span>get started</span>
            </a> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
